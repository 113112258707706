<template>
    <div v-if="isMounted">
        <div class="vx-row no-gutter items-center justify-center">
            <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
                <div class="vx-row no-gutter items-center justify-center p-6">
                    <vx-card class="login-card py-3 px-3">
                        <div slot="no-body" class="">
                            <div class="vx-col sm:w-full">
                                <div class="login-tabs-container">
                                    <div class="vx-row m-0">
                                        <div class="vx-col w-full p-0" style="min-height: 200px">
                                            <!-- MAIN-DIV -->
                                            <div class="px-6 pt-5 pb-5">
                                                <!-- HEADER -->
                                                <div class="vx-row mb-4">
                                                    <div class="vx-col w-full">
                                                        <p class="text-2xl card-sub-title">¡Necesitamos tu ayuda!</p>
                                                    </div>
                                                </div>
                                                <vs-divider color="dark"></vs-divider>
                                                <!-- CONTENT -->
                                                <div class="main-form mt-base">
                                                    <div class="vx-row">
                                                        <div :class="colClassFull">
                                                            <p>
                                                                En la Red es importante mantener los expedientes actualizados de nuestros Proveedores.
                                                                <br>
                                                                <br>
                                                                Por favor ponte en contacto con la persona que maneja la cuenta administradora y pídele
                                                                que inicie la sesión para que nos ayude actualizado el expediente y así puedas entrar a tu portal.
                                                                <br>
                                                                <br>
                                                                <strong>¡Gracias!</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- END CONTENT DIV -->
                                            </div>
                                            <!-- END MAIN-DIV -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vx-card>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "SupplierOnboardingAgentsPage",
    async mounted() {

        this.isMounted = true;
    },
    data() {
        return {
            isMounted: false,
            showSupplierWelcomeMessage: false,
            colClassFull: "vx-col w-full mb-5",

        }
    }
}
</script>